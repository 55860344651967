import { SignalWifiOff } from "@mui/icons-material";
import React from "react";
import { Detector } from "react-detect-offline";

const CheckConnection = (props) => {
  return (
    <>
      <Detector
        render={({ online }) =>
          online ? (
            props.children
          ) : (
            <div
              style={{
                marginTop: "50px",
                paddingTop: "10px",
                textAlign: "center",
              }}
            >
              <SignalWifiOff
                sx={{
                  height: 100,
                  width: 100,
                }}
              />
              <h1 style={{ marginBottom: "5px" }}>No internet connection</h1>
              <h4 style={{ margin: "0" }}>
                Please check your internet connection
              </h4>
            </div>
          )
        }
      />
    </>
  );
};

export default CheckConnection;
